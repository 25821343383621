import { Button, Classes, Icon, Intent, Popover, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IBudgetEntry, IEntry } from "../../types/types";
import { parseDate } from "../../utils/utils";
import { LinkWithIcon } from "../common/EditableCellWithSelect";
import MoneyValue from "../common/MoneyValue";
import EntriesTHead from "../entries/EntriesTHead";

const COLUMNS = ["value", "payee", "account"];

interface BudgetEntryRealSpendingsProps {
  entries: Partial<IEntry>[];
  spendings: number;
  budgetEntry: IBudgetEntry;
}

const BudgetEntryRealSpendings: React.FC<BudgetEntryRealSpendingsProps> = ({
  entries,
  spendings,
  budgetEntry,
}) => {
  const grouped = _.groupBy(entries, ({ date }) => date && parseDate(date).toFormat("yyyy-MM-dd"));
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleInteraction = useCallback((state: boolean) => {
    if (!state) {
      setIsOpen(false);
    }
  }, []);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <div>
      {_.isEmpty(entries) ? (
        <MoneyValue value={0} />
      ) : (
        <Popover
          minimal
          isOpen={isOpen}
          hasBackdrop
          interactionKind="click"
          targetTagName="div"
          onInteraction={(state) => handleInteraction(state)}
          className="block"
          content={
            <div className="entries-table-container">
              <div className="flex flex-row items-center">
                <h4 className={`${Classes.HEADING} flex flex-row items-center w-full mb-3`}>
                  <div className="block flex-grow mr-2">{budgetEntry.category.name}</div>
                  <MoneyValue value={spendings} />
                </h4>
              </div>
              <div className="entries-table-container__scrollable">
                <table className="entries-table w-full max-w-full table-fixed">
                  <EntriesTHead columns={COLUMNS} />
                  <tbody>
                    {_.map(grouped, (entries, date) => (
                      <>
                        <tr>
                          <td colSpan={COLUMNS.length} className="">
                            <div className="pt-2 pb-2 font-semibold">
                              {parseDate(date).toFormat("dd MMMM yyyy")}
                            </div>
                          </td>
                        </tr>
                        {_.map(entries, (entry) => (
                          <tr key={entry.id} className={`budget-table__row--${entry.type}`}>
                            {_.map(COLUMNS, (columnKey) => {
                              return (
                                <td
                                  key={columnKey}
                                  className="border border-white dark:border-gray-700"
                                >
                                  <Column column={columnKey} entry={entry} />
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-right">
                <Button intent={Intent.PRIMARY} text={t("actions.close")} onClick={close} />
              </div>
            </div>
          }
        >
          <div className="flex flex-row items-center w-full">
            <div className="flex-grow mr-2">
              <MoneyValue value={spendings} />
            </div>
            <Tooltip usePortal content={<div>{t("tooltips.show_entries")}</div>}>
              <Icon icon={IconNames.LIST_DETAIL_VIEW} onClick={open} />
            </Tooltip>
          </div>
        </Popover>
      )}
    </div>
  );
};

const Column = ({ column, entry }: { column: string; entry: Partial<IEntry> }) => {
  const getValue = (column: string, entry: Partial<IEntry>) => {
    switch (column) {
      case "value":
        return <MoneyValue value={entry.value as number} />;
      case "payee":
        return entry.payee ? <LinkWithIcon entry={entry.payee} editingKey="payee" /> : null;
      case "account":
        return entry.account ? <LinkWithIcon entry={entry.account} editingKey="account" /> : null;
      default:
        return "";
    }
  };
  return <div className="p-2">{getValue(column, entry)}</div>;
};

export default BudgetEntryRealSpendings;
