import {
  Alignment,
  Button,
  Colors,
  Divider,
  EditableText,
  FormGroup,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { FormEvent, KeyboardEvent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ICategory, IFormSchema, formField } from "../../types/types";
import { FORM_INPUTS } from "../../utils/constants";
import AccountSelect from "../common/AccountSelect";
import CategoriesSelect from "../common/CategoriesSelect";
import CurrencySelect from "../common/CurrencySelect";
import DialogFooter from "../common/DialogFooter";
import PayeeSelect from "../common/PayeeSelect";
import MultiEntries from "./MultiEntries";
import { TValidationResults, getValidationErrorMessage, validateForm } from "./validators";
import { t } from "i18next";

const DEFAULT_ICON =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIGZpbGw9IiMwMDAwMDAiIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDMyIDMyIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPm5vLWltYWdlPC90aXRsZT48cGF0aCBkPSJNMzAsMy40MTQxLDI4LjU4NTksMiwyLDI4LjU4NTksMy40MTQxLDMwbDItMkgyNmEyLjAwMjcsMi4wMDI3LDAsMCwwLDItMlY1LjQxNDFaTTI2LDI2SDcuNDE0MWw3Ljc5MjktNy43OTMsMi4zNzg4LDIuMzc4N2EyLDIsMCwwLDAsMi44Mjg0LDBMMjIsMTlsNCwzLjk5NzNabTAtNS44MzE4LTIuNTg1OC0yLjU4NTlhMiwyLDAsMCwwLTIuODI4NCwwTDE5LDE5LjE2ODJsLTIuMzc3LTIuMzc3MUwyNiw3LjQxNDFaIi8+PHBhdGggZD0iTTYsMjJWMTlsNS00Ljk5NjYsMS4zNzMzLDEuMzczMywxLjQxNTktMS40MTYtMS4zNzUtMS4zNzVhMiwyLDAsMCwwLTIuODI4NCwwTDYsMTYuMTcxNlY2SDIyVjRINkEyLjAwMiwyLjAwMiwwLDAsMCw0LDZWMjJaIi8+PHJlY3QgaWQ9Il9UcmFuc3BhcmVudF9SZWN0YW5nbGVfIiBkYXRhLW5hbWU9IiZsdDtUcmFuc3BhcmVudCBSZWN0YW5nbGUmZ3Q7IiBjbGFzcz0iY2xzLTEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIvPjwvc3ZnPg==";

//@ts-ignore
const renderMenu = ({ items, itemsParentRef, query, renderItem }) => {
  //@ts-ignore
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  return (
    <Menu className="select-options-menu" ulRef={itemsParentRef}>
      {renderedItems}
    </Menu>
  );
};

//@ts-ignore
const renderItem = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem active={modifiers.active} key={item.id} text={item.name} onClick={handleClick} />
  );
};

interface IValueInput {
  entityKey: string;
  onChange: (k: string, value: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  value: number | string;
}

export const ValueInput = ({ entityKey, onChange, onKeyDown, value }: IValueInput) => {
  return (
    <InputGroup
      autoFocus
      value={value?.toString()}
      onKeyDown={onKeyDown || undefined}
      onChange={(e) => {
        const value = e.target.value
          .replace(",", ".")
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1");
        onChange(entityKey, value);
      }}
    />
  );
};

export const getFieldLabel = (field: formField, isTransfer = false) => {
  return field.tooltip ? (
    <Tooltip usePortal content={<div>{i18n.t(field.tooltip) as string}</div>}>
      <div className="inline-block">
        {i18n.t(isTransfer ? `${field.label}_transfer` : field.label) as string}
        <span className="ml-1 text-red-800">{field.required ? "*" : ""}</span>
      </div>
    </Tooltip>
  ) : (
    <div className="inline-block">
      {i18n.t(isTransfer ? `${field.label}_transfer` : field.label) as string}
      <span className="ml-1 text-red-800">{field.required ? "*" : ""}</span>
    </div>
  );
};

//@ts-ignore
function fieldMapper(entity, validationResults, onChange, additionalOptions) {
  return (field: formField | formField[], key: string): ReactNode => {
    if (_.isArray(field)) {
      return (
        <div className="flex flex-col sm:flex-row">
          {_.map(field, (f, index) => {
            return (
              <React.Fragment>
                <div className="flex-0 sm:flex-1 ">
                  {fieldMapper(
                    entity,
                    validationResults,
                    onChange,
                    additionalOptions
                  )(f, f.key || "")}
                </div>
                {index !== field.length - 1 && <Divider className="mx-5" />}
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      switch (field.type) {
        case FORM_INPUTS.TEXT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <InputGroup
                autoFocus
                value={entity[key]}
                onChange={(e) => onChange(key, e.target.value)}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.NUMBER: {
          return (
            <FormGroup
              inline={field.inline || false}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <ValueInput key={key} value={entity[key]} entityKey={key || ""} onChange={onChange} />
            </FormGroup>
          );
        }
        case FORM_INPUTS.SELECT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <Select
                itemListRenderer={renderMenu}
                items={field.options as { value: string; text: string }[]}
                onItemSelect={(item) => onChange(key, item)}
                itemRenderer={renderItem}
              >
                <Button text={field.selectedOption} rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />
              </Select>
            </FormGroup>
          );
        }
        case FORM_INPUTS.CATEGORIES_SELECT: {
          const options = additionalOptions[key] || {};
          const errorMsg = getValidationErrorMessage(validationResults, key);

          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_account_change_warning")
            : "";

          return (
            <FormGroup
              key={key}
              helperText={helperText}
              inline={field.inline || false}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              disabled={entity.transfer && entity.id}
              label={getFieldLabel(field)}
              className={key}
            >
              <CategoriesSelect
                includeTransfers={true && !additionalOptions.isTransfer}
                isTransfer={additionalOptions.isTransfer || false}
                filterOutCategories={options.filterOutCategories || []}
                filterOutNestedCategories={options.filterOutNestedCategories}
                onChange={(category: ICategory) => onChange(key, category)}
                initialCategory={entity[key]}
                disabled={entity.transfer && entity.id}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.RICH_TEXT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <EditableText
                multiline={true}
                minLines={2}
                maxLines={4}
                value={entity[key]}
                onChange={(value) => onChange(key, value)}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.PAYEE_SELECT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <PayeeSelect
                disabled={additionalOptions.isTransfer || false}
                //@ts-ignore
                userId={additionalOptions.userId}
                //@ts-ignore
                onChange={(payee) => onChange(key, payee)}
                initialPayee={entity[key]}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.ACCOUNT_SELECT: {
          const errorMsg = getValidationErrorMessage(validationResults, key);
          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_account_change_warning")
            : "";
          return (
            <FormGroup
              key={key}
              inline={field.inline || false}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              disabled={entity.transfer && entity.id}
              helperText={helperText}
              label={getFieldLabel(field, entity.transfer)}
              className={key}
            >
              <AccountSelect
                //@ts-ignore
                disabled={entity.transfer && entity.id}
                onChange={(account) => onChange(key, account)}
                initialAccount={entity[key]}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.DATE_INPUT: {
          const errorMsg = getValidationErrorMessage(validationResults, key);

          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_date_change_warning")
            : "";

          return (
            <FormGroup
              inline={field.inline || false}
              key={key}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              helperText={helperText}
              label={getFieldLabel(field)}
              className={key}
              disabled={entity.transfer && entity.id}
            >
              <DateInput3
                disabled={entity.transfer && entity.id}
                onChange={(date) => {
                  onChange(key, date);
                }}
                value={entity[key].toString()}
                formatDate={(date) => {
                  return DateTime.fromJSDate(date).toFormat("cccc dd/MM/yyyy HH:mm");
                }}
                parseDate={(str: string) => {
                  return new Date(str);
                }}
                placeholder="dd/MM/yyyy"
                timePrecision="minute"
                showTimezoneSelect={false}
                timePickerProps={{
                  showArrowButtons: true,
                }}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.TRANSFER_SWITCH: {
          return (
            <React.Fragment>
              <Switch
                alignIndicator={Alignment.LEFT}
                key={key}
                disabled={!_.isEmpty(additionalOptions[key].disabled)}
                checked={entity["transfer"]}
                labelElement={getFieldLabel(field)}
                onChange={additionalOptions[key].onChange}
              />
              <Divider className="mb-6" />
            </React.Fragment>
          );
        }

        case FORM_INPUTS.RADIO_BUTTONS: {
          return (
            <FormGroup
              inline={field.inline || false}
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <RadioGroup
                inline={additionalOptions[key]?.inline}
                disabled={additionalOptions.isTransfer}
                onChange={(event: FormEvent<HTMLInputElement>) => {
                  onChange(key, event.currentTarget.value);
                }}
                selectedValue={entity[key] || field.defaultValue}
              >
                {_.map(field.options, (option, idx) => (
                  <Radio key={`${option}_${idx}`} label={t(option.text)} value={option.value} />
                ))}
              </RadioGroup>
            </FormGroup>
          );
        }

        case FORM_INPUTS.SWITCHER: {
          return (
            <Switch
              key={key}
              checked={entity[key]}
              labelElement={getFieldLabel(field)}
              //@ts-ignore
              onChange={(e) => onChange(key, e.target.checked)}
            />
          );
        }

        case FORM_INPUTS.MULTIENTRIES: {
          return (
            <FormGroup
              inline={field.inline || false}
              key={"splitted_entries"}
              label={getFieldLabel(field)}
              className={key}
            >
              <MultiEntries
                entity={entity}
                onChange={onChange}
                field={field}
                validationResults={validationResults}
              />
            </FormGroup>
          );
        }

        case FORM_INPUTS.CURRENCY_SELECTOR: {
          return (
            <FormGroup
              inline={field.inline || false}
              key="currency"
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, "splitted_entries")}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <CurrencySelect
                selected={
                  _.isString(entity[key])
                    ? JSON.parse(_.isEmpty(entity[key]) ? "{}" : entity[key])
                    : entity[key]
                }
                onItemSelect={(selectedCurrency: any) => onChange("currency", selectedCurrency)}
              />
            </FormGroup>
          );
        }

        case FORM_INPUTS.ICON_INPUT: {
          return (
            <IconInput
              entity={entity}
              entityKey={key}
              field={field}
              validationResults={validationResults}
              onChange={onChange}
            />
          );
        }

        default: {
          return <div>Not handled</div>;
        }
      }
    }
  };
}

interface IFormBuilder {
  additionalOptions?: Record<string, unknown>;
  applyLabel?: string;
  entity: object;
  formSchema: IFormSchema;
  onApply: any;
  onApplyAndNext?: any;
  onCancel: any;
  onChange: any;
  withAddNext?: boolean;
}

const FormBuilder = ({
  additionalOptions = {},
  applyLabel,
  entity,
  formSchema,
  onApply,
  onApplyAndNext,
  onCancel,
  onChange,
  withAddNext = false,
}: IFormBuilder) => {
  const [validationResults, setValidationResults] = useState<TValidationResults>({});

  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();

  const _onChange = (key: string, value: unknown) => {
    setValidationResults({ ...validationResults, [key]: [true, ""] });
    onChange(key, value);
  };

  const _onApply =
    (createNext = false) =>
    (e: React.FormEvent) => {
      e.preventDefault();
      const vr = validateForm(entity, formSchema.validators);
      setValidationResults(vr);
      if (!_.isEmpty(vr)) return;
      setSaving(true);
      const applyFn = createNext ? onApplyAndNext : onApply;
      applyFn(entity).finally(() => {
        setSaving(false);
      });
    };

  const isValidForm = _.every(validationResults, (result) => result[0]);

  return (
    <form>
      {_.map(
        formSchema.fields,
        fieldMapper(entity, validationResults, _onChange, additionalOptions)
      )}
      <DialogFooter
        applyEnabled={isValidForm}
        applyLabel={applyLabel || t("labels.save")}
        onApply={_onApply(false)}
        onApplyAndNext={_onApply(true)}
        onCancel={onCancel}
        saving={saving}
        withAddNext={withAddNext}
      />
    </form>
  );
};
interface IIconInput {
  entity: Record<string, string>;
  entityKey: string;
  onChange: (k: string, value: string) => void;
  field: formField;
  validationResults: TValidationResults;
}

const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

const IconInput = ({ entity, field, validationResults, entityKey, onChange }: IIconInput) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const MAX_ICON_SIZE = 1 * 1024 * 1024;
  const { t } = useTranslation();
  const updateFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file.size > MAX_ICON_SIZE) {
      setValidationError(
        t("errors.validations.file_too_large", {
          name: file.name,
          size: MAX_ICON_SIZE / (1024 * 1024),
        })
      );
      return;
    } else {
      setValidationError(null);
    }
    getBase64(file as File).then((result) => {
      onChange(entityKey, result as string);
    });
  };
  return (
    <div>
      <div className="flex flex-row items-center mb-3">
        <img
          className="w-6 h-6 cursor-pointer"
          src={entity[entityKey] ? entity[entityKey] : DEFAULT_ICON}
          alt="icon"
          onClick={() => inputRef.current?.click()}
        />
        <label htmlFor="icon-input-field" className="ml-2 cursor-pointer">
          {getFieldLabel(field)}
        </label>
        <input
          id="icon-input-field"
          className="hidden"
          accept="image/*"
          ref={inputRef}
          type="file"
          onChange={updateFile}
        />
      </div>
      <div className="text-red-600 my-2">
        {validationError || getValidationErrorMessage(validationResults, entityKey)}
      </div>
    </div>
  );
};

export default FormBuilder;
