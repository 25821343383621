import { useMutation } from "@apollo/client";
import { InputGroup, Intent } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import { DateTime } from "luxon";
import { KeyboardEvent, ReactElement, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_BUDGET_ENTRY,
  INLINE_UPDATE_BUDGET_ENTRY,
  SOFT_DELETE_BUDGET_ENTRY,
} from "../../graphql/mutations/budgetEntry";
import { BUDGET_SECTIONS } from "../../graphql/queries/budgetSections";
import { IBudgetEntry } from "../../types/types";
import {
  calculateSpendings,
  getEntriesToBeDisplayedOnBudget,
  useGetStylesForRows,
} from "../../utils/budgetUtils";
import { graphQlError, parseDate } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import ValueWithEditMode from "../common/ValueWithEditMode";
import { Notifications } from "../common/notifications";
import { ValueInput } from "../forms/FormBuilder";
import BudgetEntryRealSpendings from "./BudgetEntryRealSpendings";

interface IBudgetEntryProps {
  index: number;
  budgetEntry: IBudgetEntry;
  budgetSectionId: number;
  onEdit: (budgetEntry: IBudgetEntry) => void;
  onDelete: (budgetEntry: IBudgetEntry) => void;
  selectedDate: DateTime;
}

export default function BudgetEntry({
  index,
  budgetEntry,
  budgetSectionId,
  onEdit,
  onDelete,
  selectedDate,
}: IBudgetEntryProps): ReactElement {
  const userData = useContext(UserContext);
  const currentBudget: number = (_.first(userData?.budgets) || {}).id as number; // if we're here we have at least one budget

  const { t } = useTranslation();
  const entries = getEntriesToBeDisplayedOnBudget(budgetEntry, selectedDate);

  const spendings = calculateSpendings(entries);

  const [updateBudgetEntry] = useMutation(INLINE_UPDATE_BUDGET_ENTRY);
  const [createBudgetEntry] = useMutation(CREATE_BUDGET_ENTRY, {
    refetchQueries: [
      {
        query: BUDGET_SECTIONS,
        variables: {
          budgetId: currentBudget,
          periodStart: selectedDate.startOf("month"),
          periodEnd: selectedDate.endOf("month"),
        },
      },
    ],
  });

  const [deleteBudgetEntry] = useMutation(SOFT_DELETE_BUDGET_ENTRY);
  const [editing, setEditing] = useState<{ key: string; value: string | number } | null>(null);

  const update = () => {
    const entryMonth = parseDate(budgetEntry.date).toFormat("M");
    const selectedMonth = selectedDate.toFormat("M");
    (selectedMonth === entryMonth
      ? updateBudgetEntry({
          variables: {
            id: budgetEntry.id,
            changes: {
              [editing?.key as string]: editing?.value,
              date: budgetEntry.id ? budgetEntry.date : selectedDate,
            },
            periodStart: selectedDate.startOf("month"),
            periodEnd: selectedDate.endOf("month"),
          },
        })
      : Promise.all([
          createBudgetEntry({
            variables: {
              object: {
                ..._.omit(
                  budgetEntry,
                  "id",
                  "created_at",
                  "updated_at",
                  "__typename",
                  "entries",
                  "category"
                ),
                category_id: budgetEntry.category.id,
                budget_id: currentBudget,
                budget_section_id: budgetSectionId,
                //@ts-ignore
                user_id: userData.user_id,
                date: selectedDate,
                [editing?.key as string]: editing?.value,
              },
              periodStart: selectedDate.startOf("month"),
              periodEnd: selectedDate.endOf("month"),
            },
          }),
          deleteBudgetEntry({
            variables: {
              id: budgetEntry.id,
              deleted_at: selectedDate,
              periodStart: selectedDate.startOf("month"),
              periodEnd: selectedDate.endOf("month"),
            },
          }),
        ])
    )
      .then(() => {
        Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(() => {
        setEditing(null);
      });
  };

  const cancelEditing = () => {
    setEditing(null);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") update();
    if (e.key === "Escape") {
      cancelEditing();
    }
  };

  return (
    <BudgetEntryRow value={budgetEntry.value} spendings={spendings}>
      <td>{index}</td>
      <td>
        <InternalLink to={`/categories/${budgetEntry.category?.id}`}>
          {budgetEntry.category?.name}
        </InternalLink>
      </td>
      <td>
        <BudgetEntryRealSpendings
          budgetEntry={budgetEntry}
          entries={entries}
          spendings={spendings}
        />
      </td>
      <td onClick={() => setEditing({ key: "value", value: budgetEntry.value })} onBlur={update}>
        <ValueWithEditMode
          value={<MoneyValue value={budgetEntry.value} />}
          onAccept={update}
          onCancel={cancelEditing}
          editing={editing?.key === "value"}
          edit={
            <ValueInput
              entityKey="value"
              onChange={(key, value) => setEditing({ key, value })}
              onKeyDown={onKeyDown}
              value={editing?.value || ""}
            />
          }
        />
      </td>
      <td onClick={() => setEditing({ key: "name", value: budgetEntry.name })} onBlur={update}>
        <ValueWithEditMode
          editing={editing?.key === "name"}
          edit={
            <InputGroup
              type="text"
              className="w-full py-1 pl-1 pr-10"
              value={editing?.value as string}
              onChange={(e) => setEditing({ key: "name", value: e.target.value })}
              onKeyDown={onKeyDown}
            />
          }
          value={budgetEntry.name}
          onAccept={update}
          onCancel={cancelEditing}
        />
      </td>
      <td className="budget-table-actions">
        <Actions entry={budgetEntry} onDelete={onDelete} onEdit={onEdit} />
      </td>
    </BudgetEntryRow>
  );
}

interface IBudgetEntryRow {
  children: ReactNode;
  value: number;
  spendings: number;
}

const BudgetEntryRow = ({ children, value, spendings }: IBudgetEntryRow) => {
  const { className, styles } = useGetStylesForRows(value, spendings);
  return (
    <tr className={`budget-entry budget-entry--${className}`} style={styles}>
      {children}
    </tr>
  );
};
